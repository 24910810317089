<template>
	<section
		:class="[bg, bg && bg !== 'transparent' && 'padding-vertical-xl', { dark }]"
		class="list-icons"
	>
		<TnHeading
			v-if="heading"
			v-editable-string
			:dark="dark"
			class="margin-bottom-2xl"
			responsive
			type="h3"
			>{{ heading }}
		</TnHeading>
		<div
			:class="iconLayoutClass"
			class="icons"
		>
			<ListIconsIcon
				v-for="(icon, index) in iconList"
				:key="`icon-${index}`"
				:dark="dark"
				:icon="icon"
			/>
		</div>
		<LinkInline
			v-if="link"
			:dark="dark"
			class="margin-top-xl"
			size="m"
			v-bind="link"
		/>
	</section>
</template>

<script>
import striptags from "striptags";
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => ({})
    },
    component: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props){
    const backgroundColor = useBackgroundColor(props.content?.backgroundColor);
    return {
      backgroundColor
    }
  },

	computed: {
		iconLayoutClass() {
			return getLayoutClasses(this.iconList.length);
		},
		bg() {
			if (this.color) return this.backgroundColor;
			else return "transparent";
		},
		heading() {
			return this.content.iconHeading && striptags(this.component.content.iconHeading);
		},
		link() {
			return this.content && this.content.iconLink && LinkViewModel(this.content.iconLink);
		},
		color() {
			return this.content && this.content.backgroundColor;
		},
		iconList() {
			return this.content && this.component.content.iconList;
		},
	},
});
</script>

<style lang="scss" scoped>
.list-icons {
	text-align: center;

	.icons {
		display: grid;
	}

	@include breakpoint(mobile) {
		text-align: left;
	}
}
</style>
